import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { clearDiscountCode } from "@/utils/discount-code-client";
import { useAPI } from "@/contexts/APIContext";

function timeLeftToString(timeLeft) {
  const { hours, minutes, seconds } = timeLeft;
  const minutesAndSeconds =
    minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
  if (hours > 0) {
    return hours.toString().padStart(2, "0") + ":" + minutesAndSeconds;
  } else {
    return minutesAndSeconds;
  }
}

const TimeLeft = ({ date }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const diff = date - now;
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);
    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Calculate how long until it expires
  useEffect(() => {
    const interval = setInterval(() => setTimeLeft(calculateTimeLeft()), 1000);
    setTimeLeft(calculateTimeLeft());
    return () => window.clearInterval(interval);
  }, []);

  if (new Date() > date) {
    clearDiscountCode();
  }

  // Don't show the time left if it's more than 3 days
  if (timeLeft.hours > 72) {
    return null;
  }

  return (
    <Text textAlign="center" mt={0.5}>
      ⚡️ Hurry! You only have <b>{timeLeftToString(timeLeft)}</b> left. ⚡️
    </Text>
  );
};

// export function DiscountBanner() {
//   const [discountData, setDiscountData] = useState({});
//
//   useEffect(() => {
//     const refresh = () => {
//       setDiscountData(getDiscountData());
//     };
//
//     setInterval(refresh, 500);
//     return () => window.clearInterval(refresh);
//   }, []);
//
//   const { discountCode, discountExpireAt } = discountData;
//
//   if (!discountCode) {
//     return null;
//   }
//
//   return (
//     <Box
//       p={2}
//       bg="linear-gradient(90deg, #5BE0E6 0%, #004AAD 100%)"
//       textAlign="center"
//       color="white"
//       cursor="pointer"
//       onClick={() => (window.location = "/plans")}
//     >
//       <Text>
//         <DiscountText {...discountData} />
//       </Text>
//       <TimeLeft date={new Date(discountExpireAt)} />
//     </Box>
//   );
// }

export function DiscountBanner() {
  const api = useAPI();

  const [discountBanner, setDiscountBanner] = useState({});

  useEffect(() => {
    const getBannerData = async () => {
      const bannerData = await api.getUnprotected("/discount-banner", true);
      setDiscountBanner(bannerData);
    };

    getBannerData().catch(() => {});
  }, []);

  const { isActive, isValid, discountCode } = discountBanner;

  if (!isActive || !isValid) {
    return null;
  }

  const color1 = discountBanner?.color1 || "#5BE0E6";
  const color2 = discountBanner?.color2 || "#004AAD";
  const colorText = discountBanner?.colorText || "white";

  return (
    <Box
      p={2}
      bg={`linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`}
      textAlign="center"
      color={colorText}
      cursor="pointer"
      onClick={() => (window.location = "/plans")}
    >
      <Text>{discountBanner.text}</Text>
      <TimeLeft date={new Date(discountCode.expireAt)} />
    </Box>
  );
}
