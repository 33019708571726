import { initializeApp, getApps, getApp } from "firebase/app";
import { isDev } from "./data/constants";
import { getAuth as getFirebaseAuth } from "firebase/auth";

export const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyD6bHXO96c5M39nBN5O0EdGRqJ5UHLxnec",
      authDomain: "whogetsin.firebaseapp.com",
      databaseURL: "https://whogetsin-default-rtdb.firebaseio.com",
      projectId: "whogetsin",
      storageBucket: "whogetsin.appspot.com",
      messagingSenderId: "832355500979",
      appId: "1:832355500979:web:cfbb3190357df0d53c81c6",
      measurementId: "G-P09Q5W7VLN",
    }
  : {
      apiKey: "AIzaSyCDD_4HFi2aM9693mFzMY46Glut7_7F2Ag",
      authDomain: "admit-yogi.web.app",
      projectId: "admit-yogi",
      storageBucket: "admit-yogi.appspot.com",
      messagingSenderId: "498420389423",
      appId: "1:498420389423:web:ffea08bfd2d9466218d8da",
      measurementId: "G-M217R3SLBG",
    };

const needsToInitialize = getApps().length == 0;
const app = needsToInitialize ? initializeApp(firebaseConfig) : getApp();

export const getAuth = () => {
  if (typeof window !== "undefined") {
    console.log("Firebase authenticator loading...");
  }
  return getFirebaseAuth();
};

export default app;
