import { extendTheme } from "@chakra-ui/react";
import button from "./button";
import form from "./form";

const theme = extendTheme({
  colors: {
    beige: "#fff3e3",
    olive: {
      500: "#7CA029",
    },
    commonappBlue: {
      500: "#0B6DBD",
    },
    darkBlue: {
      100: "#578ab3",
      200: "#4d7ea7",
      300: "#426d96",
      400: "#395d84",
      500: "#2b4264",
      600: "#223450",
      700: "#19273c",
      800: "#111a28",
      900: "#080d13",
    },
    darkGreen: {
      100: "#3d9e7f",
      200: "#389173",
      300: "#328164",
      400: "#2c7057",
      500: "#21543d",
      600: "#1a4330",
      700: "#133224",
      800: "#0d2118",
      900: "#06100c",
    },
    brightYellow: {
      100: "#fbfdc5",
      200: "#f7fa9d",
      300: "#f3f674",
      400: "#fcff5f",
      500: "#f8fa3e",
      600: "#fceb38",
      700: "#fdd22b",
      800: "#fdba1c",
      900: "#fc9000",
    },
    black: {
      500: "#000000",
      700: "#000000",
      800: "#000000",
    },
    tomatoRed: {
      700: "#5B0E0E",
      500: "#EE4266",
    },
    babyBlue: "#2485d0",
  },
  components: {
    Button: button,
    Form: form,
    Container: {
      baseStyle: {
        maxW: "container.lg",
      },
    },
  },
  styles: {
    global: {
      h1: {
        fontWeight: "bold",
        fontSize: "2.5rem",
      },
      h2: {
        fontWeight: "bold",
        fontSize: "2.25em",
      },
      h3: {
        fontWeight: "bold",
        fontSize: "1.5em",
      },
      h4: {
        fontWeight: "bold",
        fontSize: "1.3em",
      },
    },
  },
  fonts: {
    heading: `'Raleway', sans-serif`,
    headingFancy: `'Gloock', 'Raleway', sans-serif`,
    body: `'Nunito', sans-serif`,
  },
  layerStyles: {
    md: {
      fontSize: "xl",
      p: {
        marginTop: "1em",
      },
    },
    document: {
      p: { mt: 4 },
      ol: {
        ml: 6,
      },
      "h1, h2, h3": {
        mt: 4,
        fontWeight: "bold",
        fontSize: "xl",
      },
    },
  },
  textStyles: {
    pageHeader: {
      fontSize: { base: "5xl", md: "7xl" },
      fontFamily: `'Raleway', sans-serif`,
      fontWeight: "900",
      letterSpacing: "-0.015em",
    },
  },
});

export default theme;
