import { useAPI } from "@/contexts/APIContext";
import { useUserData } from "@/contexts/UserDataContext";

import { useEffect, useState } from "react";

export const useGetEssaysPath = () => {
  const userData = useUserData();
  const api = useAPI();

  const [token, setToken] = useState("");

  useEffect(() => {
    if (userData?.currentUser) {
      try {
        api.post("users/get-custom-token", true).then((response) => {
          if (response?.result === "success") {
            setToken(response.customToken);
          }
        });
      } catch (e) {}
    }
  }, [userData]);

  return token
    ? `${process.env.NEXT_PUBLIC_ESSAYS_DOMAIN}?token=${token}`
    : process.env.NEXT_PUBLIC_ESSAYS_DOMAIN;
};
