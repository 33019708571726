import axios from "axios";
import { getAuth } from "./firebase-init";

export async function runAxios(route, method, user, params, postData) {
  let url = "/api/" + route;
  const paramsBuilder = params ? { ...params } : {};
  try {
    if (!!user) {
      paramsBuilder.token = await getAuth().currentUser?.getIdToken();
    }
  } catch (e) {
    if (e.code == "auth/user-token-expired") {
      // Force "Invalid token" error from server
      paramsBuilder.token = "";
    } else {
      throw e;
    }
  }

  const allParams = Object.entries(paramsBuilder);
  if (allParams.length > 0) {
    if (!url.includes("?")) url += "?";
    else if (!url.endsWith("&")) url += "&";
    for (const [key, value] of allParams) url += key + "=" + value + "&";
  }

  const response = await axios[method](url, postData);
  if (response.data) {
    const { result } = response.data;
    if (result == "success") {
      return response.data;
    } else if (result == "failure") {
      if ("reason" in response.data) {
        throw new Error(response.data.reason);
      } else {
        throw new Error(JSON.stringify(response.data));
      }
    } else {
      return response;
    }
  } else {
    throw new Error("No data in response");
  }
}

export function get(route, user, params) {
  return runAxios(route, "get", user, params, undefined);
}

export function post(route, user, data) {
  return runAxios(route, "post", user, undefined, data);
}
