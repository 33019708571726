import { useEffect, useState, useContext, createContext } from "react";
import isUaWebview from "is-ua-webview";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";

export const DeviceContext = createContext();

export function useDevice() {
  return useContext(DeviceContext);
}

export function DeviceProvider({ children }) {
  const [isSafari, setIsSafari] = useState();
  const [isWebview, setIsWebview] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (navigator) {
      setIsSafari(
        navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") <= -1,
      );
      setIsWebview(isUaWebview(navigator.userAgent));
    }
  }, []);

  return (
    <DeviceContext.Provider value={{ isSafari, isWebview, isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
}
