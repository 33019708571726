import { createContext, useContext, useEffect, useState } from "react";
import posthog from "posthog-js";

const FeatureFlagsContext = createContext();

export const useFeatureFlag = (flag, defaultValue) => {
  const { flags, variants } = useContext(FeatureFlagsContext);
  const flagValue = flags.includes(flag);
  const variantValue = variants[flag];

  if (!flagValue || typeof window === "undefined") {
    return defaultValue;
  }
  return variantValue ?? true;
};

export const useABTest = (flag, trueValue, falseValue, defaultValue) => {
  if (!defaultValue) {
    defaultValue = trueValue;
  }
  const featureFlag = useFeatureFlag(flag, defaultValue);
  // Use double equals to allow "true" and "false"
  if (featureFlag == trueValue) {
    return true;
  } else if (featureFlag == falseValue) {
    return false;
  } else {
    return defaultValue;
  }
};

export const useFeatureFlagValue = (flag, defaultValue, map) => {
  const flagValue = useFeatureFlag(flag, defaultValue);
  return map[flagValue];
};

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState([]);
  const [variants, setVariants] = useState({});

  useEffect(() => {
    posthog.onFeatureFlags((newFlags, newVariants) => {
      // We only want to change them once - for some reason, Posthog sends multiple
      // onFeatureFlags invocations with different values the second time around
      // if (flags.length === 0 || Object.keys(variants).length === 0) {
      setFlags(newFlags);
      setVariants(newVariants);
      // }
    });
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ flags, variants }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
