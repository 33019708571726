import { chakra } from "@chakra-ui/react";
import NextImage from "next/legacy/image";

const OptimizedImage = chakra(NextImage, {
  baseStyle: { maxH: 120, maxW: 120 },
  shouldForwardProp: (prop) =>
    [
      "width",
      "height",
      "src",
      "alt",
      "title",
      "quality",
      "placeholder",
      "blurDataURL",
      "loader",
      "onMouseEnter",
      "onMouseLeave",
      "onClick",
      "layout",
      "fill",
      "sizes",
      "priority",
      "placeholder",
      "borderRadius",
    ].includes(prop),
});

export default OptimizedImage;
