import { posthog } from "posthog-js";
import { ESSAY_PLANS, PROFILE_PLANS } from "./data/products";

export const captureFB = (name, options = {}) => {
  if (window.fbq) {
    window.fbq("track", name, options);
  }
};

export const captureReddit = (name, options) => {
  if (window.rdt) {
    window.rdt("track", name, options);
  }
};

export const logEvent = (api, action, data = {}) => api.post("users/log", true, { action, data });

export const captureGtag = (name, options = {}) => {
  if (window.gtag) {
    window.gtag("event", name, options);
  }
};

export const onPageView = () => {
  captureFB("PageView");
  captureReddit("PageVisit");
};

export const onSearch = () => {
  captureFB("Search");
  captureReddit("Search");
};

export const loadRedditTag = (email) => {
  const emailOptions = email ? { email } : {};
  const alreadyInit = window.rdt && window.rdt.q && window.rdt.q.length > 0;
  window.rdt("init", "t2_qa6wy33i", {
    optOut: false,
    useDecimalCurrencyValues: true,
    ...emailOptions,
  });
  if (!alreadyInit) {
    window.rdt("track", "PageVisit");
  }
};

export const blogAnalytics = {
  onShowModal: (id) => posthog.capture("blog_cta_modal_show", { id }),
  onClickModal: (id) => posthog.capture("blog_cta_modal_click", { id }),
  onCloseModal: (id) => posthog.capture("blog_cta_modal_close", { id }),
};

export const authAnalytics = {
  onLogout: () => {
    try {
      posthog.reset();
    } catch (e) {
      console.warn(e);
    }
  },
  onLogin: (currentUser) => {
    posthog.identify(currentUser.uid, { email: currentUser.email });

    if (window.fbq) {
      window.fbq("init", process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, {
        em: currentUser.email,
      });
    }
    loadRedditTag(currentUser.email);

    captureGtag("login");
  },
};

export const profileCreateAnalytics = {
  onStartProfileCreation: () => posthog.capture("profile_create_start"),
  onCommonAppImport: () => posthog.capture("profile_create_import"),
  onRedact: () => posthog.capture("profile_create_redact"),
  onReview: () => posthog.capture("profile_create_review"),
  onFinish: () => posthog.capture("profile_create_finish"),
};

export const hsUserAnalytics = {
  onCompleteSetup: () => {
    posthog.capture("hs_setup_complete");
    captureFB("CompleteRegistration");

    captureGtag("sign_up");
    captureGtag("conversion", {
      send_to: "AW-10969275912/347qCL_0_pQYEIi8x-4o",
    });
  },
  onAddInfo: (page, questionId) => posthog.capture("hs_setup_info", { page, questionId }),
  onSetConsumerType: (type) => {
    posthog.people.set({ consumer_type: type });
  },
};

export const ericaAnalytics = {
  onClickCTA: () => posthog.capture("erica_cta_click"),
  onStartOnboarding: () => posthog.capture("erica_onboarding_start"),
  onFinishOnboarding: () => posthog.capture("erica_onboarding_finish"),
  onSendMessage: () => posthog.capture("erica_send_message"),
};

export const purchaseAnalytics = {
  onCheckoutClickBuy: ({ value }) => {
    posthog.capture("purchase_auth_modal");

    captureFB("InitiateCheckout", {
      value: value,
    });

    captureGtag("begin_checkout");
    captureGtag("conversion", {
      value: value,
      currency: "USD",
      send_to: "AW-10969275912/obofCNfC9JQYEIi8x-4o",
    });

    captureReddit("AddToCart", {
      value: value,
      currency: "USD",
      itemCount: 1,
    });
  },
  onCheckoutShowPaymentModal: () => posthog.capture("purchase_payment_modal"),
  onPurchase: (txid, pi) => {
    const currentSKUs = [pi.profilePlanSku, pi.essaysPlanSku].filter(Boolean);

    posthog.capture("purchase_complete", {
      purchase_value: pi.price,
      credits: pi.credits,
      txid: txid,
    });

    posthog.people.set_once({
      $hasPurchased: true,
    });

    captureFB("Purchase", {
      value: pi.price,
      currency: "USD",
      content_name: currentSKUs.length === 1 ? currentSKUs[0] : `Bundle ${currentSKUs.join(" + ")}`,
      content_ids: currentSKUs,
      content_type: "product",
      credits: pi.credits,
      order_id: txid,
    });

    captureGtag("purchase", {
      value: pi.price,
      currency: "USD",
      transaction_id: txid,
      items: currentSKUs.map((sku) => {
        const plan = [...PROFILE_PLANS, ...ESSAY_PLANS].find((p) => p.sku === sku);
        return {
          id: sku,
          name: plan.title,
          quantity: plan.credits,
          price: plan.price,
        };
      }),
    });

    captureGtag("conversion", {
      send_to: "AW-10969275912/RSNcCNrC9JQYEIi8x-4o",
      value: pi.price,
      currency: "USD",
      transaction_id: txid,
    });

    captureReddit("Purchase", {
      value: pi.price,
      currency: "USD",
      transactionId: txid,
      itemCount: 1,
    });
  },
};
