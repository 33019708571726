export function shouldDisplayDiscountCode(code) {
  return !code.toUpperCase().startsWith("GEN-");
}
export async function applyDiscountCode(api, code, silent) {
  if (window.localStorage.getItem("discountCode") === code) {
    return;
  }

  const discountCode = await api.get("stripe/discount-code", true, { code });

  if (!discountCode.valid) {
    // window.alert(
    //   "Invalid discount code - sorry! To stay up to date with our latest discounts, follow us on TikTok @admityogi and create an account to receive email notifications."
    // )
    return clearDiscountCode();
  }

  const multiplier = discountCode.multiplier || 1;

  const pctOffStr =
    discountCode.discountPct !== 0
      ? `${Math.round(discountCode.discountPct * 100).toString()}%`
      : undefined;
  const multiplierStr = multiplier !== 1 ? multiplier : undefined;

  window.localStorage.setItem("discountCode", code);
  window.localStorage.setItem("discountPct", discountCode.discountPct);
  window.localStorage.setItem("discountExpireAt", discountCode.expireAt);
  window.localStorage.setItem("discountMultiplier", multiplier);
  window.localStorage.setItem("discountMinAmount", discountCode.minAmount || 0);

  if (!silent) {
    if (pctOffStr && multiplierStr) {
      window.alert(
        `Successfully applied discount code ${code} for ${pctOffStr} off and ${multiplierStr}X multiplier on all of our packages!`,
      );
    } else if (pctOffStr) {
      window.alert(
        `Successfully applied discount code ${code} for ${pctOffStr} off on all of our packages!`,
      );
    } else if (multiplierStr) {
      window.alert(
        `Successfully applied discount code ${code} for ${multiplierStr}X multiplier on all of our packages!`,
      );
    }
  }

  const url = new URL(window.location.href);
  url.searchParams.set("discountCode", code);
  window.history.replaceState({}, document.title, url.toString());

  if (window.location.href.includes("/plans")) {
    window.location.reload();
  }
}

export async function clearDiscountCode() {
  window.localStorage.removeItem("discountCode");
  window.localStorage.removeItem("discountPct");
  window.localStorage.removeItem("discountExpireAt");
  window.localStorage.removeItem("discountMultiplier");
  window.localStorage.removeItem("discountMinAmount");

  const url = new URL(window.location.href);
  url.searchParams.delete("discountCode");
  window.history.replaceState({}, document.title, url.toString());
}
