import { useContext, createContext, useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  onIdTokenChanged,
  signOut,
  browserPopupRedirectResolver,
} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { useAPI } from "./APIContext";
import {
  authAnalytics,
  loadRedditTag,
  // onLogin,
  // onLogout,
} from "@/utils/analytics";
import { setCookie } from "nookies";
import { getAuth } from "@/utils/firebase-init";
import { useRouter } from "next/router";

export const UserDataContext = createContext();

const gauth = new GoogleAuthProvider();
const auth = getAuth();

export function useUserData() {
  return useContext(UserDataContext);
}

const UserDataProvider = (props) => {
  const api = useAPI();
  const router = useRouter();
  // User data might be initialized from server-side props
  const { userData: initialUserData, child: ChildComponent, ...rest } = props;
  const [currentUser, setCurrentUser] = useState();
  const [userData, setUserData] = useState(initialUserData ?? {});

  useEffect(
    () =>
      // The return value is used as an unsubscribe function
      onIdTokenChanged(auth, async (user) => {
        if (user) {
          // Set login details
          setCurrentUser(user);
          setCookie(null, "token", await user.getIdToken(), {
            path: "/",
            maxAge: 60 * 60 * 24 * 14, // 2 weeks
          });
          authAnalytics.onLogin(user);

          // Make sure user's email is verified before they can do anything
          if (!user.emailVerified) {
            const isVerifyEmailRoute = router.pathname.includes("verify-email");
            if (!isVerifyEmailRoute) {
              const currentUrl = new URL(window.location.href);
              const queryParams = currentUrl.searchParams.toString();
              await router.push(`/verify-email${queryParams ? `?${queryParams}` : ""}`);
            }
          }
        } else {
          setCurrentUser(null);
          loadRedditTag();
          setCookie(null, "token", "", { path: "/" });
          authAnalytics.onLogout();
        }
      }),
    [router],
  );

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(
      async () => {
        if (currentUser) {
          await currentUser.getIdToken(true);
        }
      },
      10 * 60 * 1000,
    );

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  const value = {
    userData,
    currentUser,
    login: (email, password) => signInWithEmailAndPassword(auth, email, password),
    loginGoogle: () => signInWithPopup(auth, gauth, browserPopupRedirectResolver),
    logout: () => {
      authAnalytics.onLogout();
      return signOut(auth);
    },
    refreshUserData: async () => {
      const { userData: newUserData } = await api.get("users/data", true);
      setUserData(newUserData ?? {});
      return newUserData;
    },
    signUp: (email, password) => createUserWithEmailAndPassword(auth, email, password),
    resetPassword: (email) => sendPasswordResetEmail(auth, email),
    changePhotoURL: (pfpUrl) => updateProfile(auth.currentUser, { photoURL: pfpUrl }),
    // sendVerification: () => sendEmailVerification(auth.currentUser),
    sendVerification: async () => {
      return api.postUnprotected("/users/send-verification-email", auth.currentUser);
    },
  };

  return (
    <UserDataContext.Provider value={value}>
      <ChildComponent {...rest} />
    </UserDataContext.Provider>
  );
};

// eslint-disable-next-line react/display-name
export const withUserData = (ChildComponent) => (props) => (
  <UserDataProvider {...props} child={ChildComponent} />
);

export const useClientsideUserData = () => {
  const { currentUser, refreshUserData } = useUserData();
  const [loaded, setLoaded] = useState(false);

  const init = async () => {
    if (!currentUser || loaded) {
      return;
    }
    await refreshUserData();
    setLoaded(true);
  };

  useEffect(() => {
    init();
  }, [currentUser]);
};
