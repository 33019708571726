import { createContext, useEffect, useState, useContext } from "react";
import * as API from "@/utils/admityogi-api";

export const APIContext = createContext();

export function useAPI() {
  return useContext(APIContext);
}

export function APIProvider({ children }) {
  const [error, setError] = useState();
  const makeProtectedCall = function (method) {
    return async (...args) => {
      try {
        return await method.apply(null, args);
      } catch (e) {
        setError(e);
      }
    };
  };
  useEffect(() => {
    if (error) throw error;
  }, [error]);

  const value = {
    get: makeProtectedCall(API.get),
    post: makeProtectedCall(API.post),
    getUnprotected: API.get,
    postUnprotected: API.post,
  };
  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
}
