import "@/styles/globals.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/400.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/900.css";
import { APIProvider } from "@/contexts/APIContext";
import NextNProgress from "nextjs-progressbar";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/theme/base-theme";
import { DeviceProvider } from "@/contexts/DeviceContext";
import { usePostHog } from "next-use-posthog";
import { isDev } from "@/utils/data/constants";
import PopupMessage from "@/components/PopupMessage";
import { useRouter } from "next/router";
import { FeatureFlagProvider } from "@/contexts/FeatureFlagsContext";
import Script from "next/script";
import { useEffect } from "react";
import { onPageView } from "@/utils/analytics";
import { setCookie } from "nookies";
import AutoDiscountCodeApplier from "@/components/plans/AutoDiscountCodeApplier";

export default function App({ Component, pageProps }) {
  const router = useRouter();

  usePostHog(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
    api_host: "https://app.posthog.com",
    respect_dnt: false,
    session_recording: {
      maskAllInputs: false,
    },
    autocapture: false,
    loaded: (posthog) => {
      if (isDev) {
        posthog.opt_out_capturing();
      }
    },
  });

  useEffect(() => {
    if (isDev) {
      return;
    }
    onPageView();
    router.events.on("routeChangeComplete", onPageView);
    return () => router.events.off("routeChangeComplete", onPageView);
  }, [router.events]);

  useEffect(() => {
    if (router.query.utm_source) {
      setCookie(null, "utm_source", router.query.utm_source, {
        maxAge: 10 * 60 * 60,
        path: "/",
      });
    }
  }, [router.query.utm_source]);

  // PATCH FOR EMAIL VERIFICATION where discount codes replace all query parameters
  // Spent days trying to fix this, don't waste more time
  const isEmailVerificationRoute = router.pathname.includes("/verify-email");

  return (
    <>
      {process.env.NEXT_PUBLIC_HOTJAR_ID && (
        <Script id="hotjar">
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </Script>
      )}

      <Script
        id="google-tag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        id="google-tag-init"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');`,
        }}
      />

      <Script
        id="reddit-tag"
        dangerouslySetInnerHTML={{
          __html: `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);`,
        }}
      />
      
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PGJXNN3P');`
        }}
      />

      {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID && (
        <>
          <Script
            id="facebook-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1544350569787128');
                fbq('track', 'PageView');
              `,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              src={`https://www.facebook.com/tr?id=1544350569787128&ev=PageView&noscript=1`}
            />
          </noscript>
        </>
      )}
        
      <Script
        id="getStartedButton"
        dangerouslySetInnerHTML={{
              __html: `document.getElementById("createAccountButton").addEventListener("click", function() {
                        fbq('trackCustom', 'FormSubmission', {formName: 'changua'});console.log("Changua");});`
        }}
      />

      <FeatureFlagProvider>
        <ChakraProvider theme={theme}>
          <DeviceProvider>
            <APIProvider>
              {!isEmailVerificationRoute && <AutoDiscountCodeApplier />}
              <PopupMessage />
              <NextNProgress color="#444" />
              <Component {...pageProps} />
            </APIProvider>
          </DeviceProvider>
        </ChakraProvider>
      </FeatureFlagProvider>
    </>
  );
}
