import { GO_PLAN } from "@/utils/data/products";

export const getDiscountData = () => {
  let discountCode = undefined;
  let discountPct = undefined;
  let discountMultiplier = undefined;
  let discountExpireAt = undefined;
  let discountMinAmount = undefined;

  if (typeof window !== "undefined") {
    const storage = window.localStorage;

    discountCode = storage.getItem("discountCode");
    discountPct = Number(storage.getItem("discountPct"));
    discountMultiplier = Number(storage.getItem("discountMultiplier")) ?? 1;
    discountExpireAt = storage.getItem("discountExpireAt");

    discountMinAmount = Number(storage.getItem("discountMinAmount"));
    if (isNaN(discountMinAmount)) {
      discountMinAmount = 1;
    }
  }

  return {
    discountCode,
    discountPct,
    discountMultiplier,
    discountExpireAt,
    discountMinAmount,
  };
};

export const getSavings = (price, credits, affiliateMultiplier = 1) => {
  const { discountMinAmount, discountPct, discountMultiplier, discountCode } = getDiscountData();

  const minAmount = discountMinAmount || 1;
  const percentage = discountPct || 0;
  const multiplier = discountCode ? discountMultiplier || 1 : affiliateMultiplier || 1;

  const ratio = GO_PLAN.price / GO_PLAN.credits;

  let newPrice = price;
  if (minAmount && newPrice >= minAmount) {
    newPrice = newPrice * (1 - percentage);
  }
  const newCredits = credits * (multiplier || 1);

  const newRatio = newPrice / newCredits;

  const savings = Math.floor(((ratio - newRatio) / ratio) * 100);

  return { savings, multiplier };
};
