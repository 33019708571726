import { toDate } from "@/utils/string";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const addDays = function (start, days) {
  var date = new Date(start.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const addHours = function (start, hours) {
  var date = new Date(start.valueOf());
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const convertUnknownToDate = (timestamp) => {
  if (!timestamp) {
    return;
  }

  if (typeof timestamp === "string") {
    return new Date(timestamp);
  }

  if (typeof timestamp === "object" && "_seconds" in timestamp) {
    return toDate(timestamp._seconds);
  }

  if (typeof timestamp === "number") {
    return toDate(timestamp);
  }
};
