import { useAPI } from "@/contexts/APIContext";
import { applyDiscountCode } from "@/utils/discount-code-client";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getDiscountData } from "@/utils/discount-service";

export default function AutoDiscountCodeApplier() {
  const router = useRouter();
  const api = useAPI();
  const queryParam = process.env.NEXT_PUBLIC_DISCOUNT_CODE_QUERY_PARAM;
  const queryParamValue = process.env.NEXT_PUBLIC_DISCOUNT_CODE_QUERY_PARAM_VALUE;

  const generateDiscountCode = async () => {
    const { code } = await api.post("stripe/create-discount-code");
    await applyDiscountCode(api, code, true);
  };

  const { discountCode: storedDiscountCode } = getDiscountData();
  const urlDiscountCode = router.query.discountCode;

  useEffect(() => {
    const syncDiscountCode = async () => {
      if (urlDiscountCode === storedDiscountCode) {
        return;
      }

      if (storedDiscountCode) {
        await router.replace({
          pathname: router.pathname,
          query: { ...router.query, discountCode: storedDiscountCode },
        });
      } else if (urlDiscountCode && urlDiscountCode !== storedDiscountCode) {
        await applyDiscountCode(api, urlDiscountCode, router.query.silent === "true");
      }
    };

    syncDiscountCode();
  }, [storedDiscountCode, urlDiscountCode, api]);

  useEffect(() => {
    if (router.query[queryParam] !== queryParamValue) {
      return;
    }

    generateDiscountCode();
  }, [router.query[queryParam]]);

  return null;
}
