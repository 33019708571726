import { useEffect, useState } from "react";
import { parseCookies, setCookie } from "nookies";
import {
  Button,
  Modal,
  chakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  ListItem,
  Text,
  Flex,
  Image,
  Box,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { LinkButton } from "./shared/generic";
import { useRouter } from "next/router";
import { useDevice } from "@/contexts/DeviceContext";

const PopupMessage = () => {
  const { isMobile } = useDevice();
  const [show, setShow] = useState(false);
  const router = useRouter();
  const id = "erica";

  const attemptShow = () => {
    const hasSeen = parseCookies()[`popup-${id}`];
    if (!hasSeen && !isMobile) {
      setShow(true);
      setCookie(undefined, `popup-${id}`, "true");
    }
  };

  useEffect(() => {
    if (router.pathname === "/erica") {
      return;
    }
    setTimeout(attemptShow, 5000);
  }, []);

  return (
    <Modal size="4xl" isOpen={show} onClose={() => setShow(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <chakra.h2 fontSize="3xl">
            Meet Erica, your AI-based college application assistant.
          </chakra.h2>
        </ModalHeader>
        <ModalBody
          fontSize="xl"
          display="flex"
          flexDir={{ base: "column", md: "row-reverse" }}
          gap={10}
          justifyContent="space-between"
        >
          <Box flex={2} mt={5}>
            Erica is a custom AI assistant that we built to answer <i>every single one</i> of your
            questions about college apps.
            <Text mt={4}>You can ask her for help with anything:</Text>
            <UnorderedList>
              <ListItem>How do I find research opportunities?</ListItem>
              <ListItem>How do I ask for a letter of recommendation?</ListItem>
              <ListItem>When do I start writing my personal statement?</ListItem>
            </UnorderedList>
          </Box>
          <Box flex={1} display={{ base: "none", md: "block" }}>
            <Image src="/images/home/howitworks/1.png" alt="A picture of erica" />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={() => setShow(false)}>
            No thanks
          </Button>
          <LinkButton
            href="/erica"
            colorScheme="green"
            rightIcon={<ChevronRightIcon />}
            mr={3}
            onClick={() => setShow(false)}
          >
            Check out Erica (for free)!
          </LinkButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PopupMessage;
